<template>
  <div class="wrapper padding-lr-sm">
    <div class="flex align-center justify-between margin-bottom-sm">
      <div class="flex align-center ">
        <el-input size="small" v-model="keyword" placeholder="请输入公司名称/联系人/电话/邮箱"
                  class=" margin-right-sm search-input"></el-input>
        <el-button size="small" type="primary" @click="hanldSearch">搜索</el-button>
      </div>

      <el-button size="small" type="primary" round @click="orgDialog = true">创建公司</el-button>

    </div>
    <el-table
        :data="orgList"
        header-row-class-name="table-header"
        row-class-name="table-row"
        alig="center"
        :cell-style="{'text-align':'center'}"
        border
        style="width: 100%"
    >
      <el-table-column width="70" type="index" align="center" label="序号"></el-table-column>
      <el-table-column label="公司名称" prop="mingcheng"></el-table-column>
      <el-table-column label="机构性质" prop="jigouxingzhi"></el-table-column>
      <el-table-column label="所属管理员" prop="adminNameStr"></el-table-column>
      <el-table-column label="法人" prop="faren"></el-table-column>
      <el-table-column label="联系人" prop="lianxiren"></el-table-column>
      <el-table-column label="电话" prop="dianhua"></el-table-column>
      <el-table-column label="邮箱" prop="youxiang"></el-table-column>
      <el-table-column label="地址" prop="dizhi"></el-table-column>
      <el-table-column width="180" label="操作">
        <template slot-scope="scope">
          <div class="table-operate">
            <div class="flex align-center justify-between margin-bottom-xs">
              <el-button size="small" type="primary" @click="editItem(scope.row)">编辑</el-button>
              <el-button size="small" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
            </div>

          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <el-pagination
        v-if="orgList.length > 0"
        class="page-all"
        background
        @current-change="getList"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
    <!--新增修改机构信息-->
    <el-drawer
        title="公司信息设置"
        :visible.sync="orgDialog"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :before-close="cancelDialog"
    >
      <div class="padding-lr">
        <el-form :model="orgForm"
                 label-width="80px"
                 :rules="rules" ref="orgForm" class="add-form">
          <el-form-item label="公司名称" prop="mingcheng">
            <el-input v-model="orgForm.mingcheng" placeholder="请填写公司名称"></el-input>
          </el-form-item>
          <el-form-item label="机构性质" prop="jigouxingzhi">
            <el-input v-model="orgForm.jigouxingzhi" placeholder="请填写机构性质"></el-input>
          </el-form-item>
          <el-form-item label="法定代表人" prop="faren">
            <el-input v-model="orgForm.faren" placeholder="请填写法定代表人"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="lianxiren">
            <el-input v-model="orgForm.lianxiren" placeholder="请填写联系人"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="dianhua">
            <el-input v-model="orgForm.dianhua" placeholder="请填写电话"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="youxiang">
            <el-input v-model="orgForm.youxiang" placeholder="请填写邮箱"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="dizhi">
            <el-input v-model="orgForm.dizhi" placeholder="请填写地址"></el-input>
          </el-form-item>
          <el-form-item label-width="0" class="flex justify-around">
            <el-button class="submit-btn" type="primary" @click="submitForm('orgForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-drawer>
  </div>
</template>

<script>

import {addOrEditOrg, delOrg, orgList} from "@/api/org";
import rules from "@/utils/rules";

export default {
  name: "OrgList",
  data() {
    return {
      keyword: '',
      page: 1,
      limit: 10,
      total: 0,
      orgList: [],
      orgDialog: false,
      orgForm: {
        mingcheng: '',
        jigouxingzhi: '',
        faren: '',
        lianxiren: '',
        dianhua: '',
        youxiang: '',
        dizhi: '',
      },
      rules: {
        mingcheng: rules.mustInput,
        jigouxingzhi: rules.mustInput,
        faren: rules.mustInput,
        lianxiren: rules.mustInput,
        dianhua: rules.mustInput,
        youxiang: rules.email,
        dizhi: rules.mustInput,
      },
      setId: 0,
      setItem: {},


    }
  },
  created() {
    this.$emit("setIndex", this.$route.path, "公司列表", false);
    this.getList()
  },
  components: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await addOrEditOrg(
          this.setId,
          this.orgForm.mingcheng,
          this.orgForm.jigouxingzhi,
          this.orgForm.faren,
          this.orgForm.lianxiren,
          this.orgForm.dianhua,
          this.orgForm.youxiang,
          this.orgForm.dizhi,
      )

      if (_state.status === 200) {
        this.cancelDialog()
      } else {
        this.$message.success(_state.info)
      }
    },
    //  编辑
    editItem(obj) {
      this.orgForm = {
        mingcheng: obj.mingcheng,
        jigouxingzhi: obj.jigouxingzhi,
        faren: obj.faren,
        lianxiren: obj.lianxiren,
        dianhua: obj.dianhua,
        youxiang: obj.youxiang,
        dizhi: obj.dizhi,
      }
      this.setId = obj.id;
      this.orgDialog = true;
    },
    //  员工信息编辑后回调
    cancelDialog() {
      this.setId = 0;
      this.setItem = {};
      this.$refs['orgForm'].resetFields();
      this.$refs['orgForm'].clearValidate();
      this.orgDialog = false;
      this.getList()
    },
    //  删除
    deleteItem(id) {
      this.$confirm('您确定要删除该公司信息吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureDelete(id);
      });
    },
    async sureDelete(id) {
      let _state = await delOrg(id);
      if (this.orgList.length === 1 && this.page > 1) {
        this.page--;
      }
      this.getList();
      this.$message.success(_state.info);
    },
    //  搜索
    hanldSearch() {
      this.page = 1
      this.getList()
    },
    //  获取各公司列表
    async getList() {
      const res = await orgList(this.page, this.limit, this.keyword)
      this.orgList = res.data
      this.total = res.total_number
    }
  },
  filters: {},
  watch: {
    orgDialog() {
      if (!this.orgDialog) this.cancelDialog()
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  .search-input {
    width: 300px;
  }
}
</style>
